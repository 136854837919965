var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-content-between align-items-center pt-50 mb-2"},[_c('h2',{staticClass:"font-weight-bolder m-0 hGoBack"},[_c('span',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('b-button',{staticClass:"btn-icon rounded-circle mr-50 text-danger bg-white",staticStyle:{"padding":"0.5px"},attrs:{"variant":"white"}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftCircleIcon","size":"35"}})],1)],1),_vm._v(" Volver al listado ")])]),_c('b-card',[_c('h3',{staticClass:"h3CardTitle"},[_vm._v("Crear Equipo")]),_c('hr'),_c('validation-observer',{ref:"createAsset"},[_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{staticClass:"product_info",attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":'Categoría',"label-for":"category"}},[_c('v-select',{attrs:{"label":"name","filterable":true,"searchable":true,"options":_vm.selectCategoriesParent,"placeholder":'Seleccionar Categoria'},model:{value:(_vm.parent_category),callback:function ($$v) {_vm.parent_category=$$v},expression:"parent_category"}})],1),_c('b-form-group',{attrs:{"label":'Subcategoría',"label-for":"product-subcategory"}},[_c('v-select',{attrs:{"label":"name","options":_vm.selectCategories,"filterable":false,"searchable":false,"placeholder":'Seleccionar subcategoría',"disabled":!_vm.parent_category},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}})],1),_c('validation-provider',{attrs:{"name":'Product',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.hideProduct)?_c('b-form-group',{attrs:{"label":'Producto',"label-for":"product"}},[_c('v-select',{ref:"products",attrs:{"label":"name","filterable":true,"searchable":true,"options":_vm.listProducts,"placeholder":'Seleccionar producto'},model:{value:(_vm.product),callback:function ($$v) {_vm.product=$$v},expression:"product"}})],1):_vm._e(),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.parent_category && _vm.parent_category.ref === 'cortinas')?_c('validation-provider',{attrs:{"name":'Mac Address',"rules":"required|macAddressUnique"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Mac Address*',"label-for":"product-mac"}},[_c('b-form-input',{attrs:{"name":"mac_address","placeholder":'Mac Address*'},model:{value:(_vm.mac_address),callback:function ($$v) {_vm.mac_address=$$v},expression:"mac_address"}})],1),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2147366394)}):_vm._e(),_c('validation-provider',{attrs:{"name":'Cliente',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Cliente',"label-for":"cliente"}},[_c('v-select',{ref:"clients",attrs:{"label":"name","filterable":true,"searchable":true,"options":_vm.selectClient,"placeholder":'Seleccionar Cliente'},model:{value:(_vm.client),callback:function ($$v) {_vm.client=$$v},expression:"client"}})],1),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.selectProyect.length > 0)?_c('validation-provider',{attrs:{"name":'Obra',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Obra',"label-for":"location"}},[_c('v-select',{ref:"projects",attrs:{"label":"name","filterable":true,"searchable":true,"options":_vm.selectProyect,"placeholder":'Seleccionar Obra'},model:{value:(_vm.project),callback:function ($$v) {_vm.project=$$v},expression:"project"}})],1),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1242647151)}):_vm._e(),(_vm.selectZones.length > 0)?_c('validation-provider',{attrs:{"name":'Zona',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Zona',"label-for":"location"}},[_c('v-select',{ref:"zones",attrs:{"label":"name","filterable":true,"searchable":true,"options":_vm.selectZones,"placeholder":'Seleccionar Zona'},model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}})],1),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2144328423)}):_vm._e()],1),_c('b-col',{staticClass:"product_info",attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":'Descripción',"label-for":"product-description"}},[_c('quill-editor',{model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('b-form-group',{attrs:{"label":'Documentos',"label-for":"documents"}},[_c('ImageDropzone',{ref:"documents",attrs:{"files":_vm.files,"maxFiles":"1"}})],1)],1),_c('b-col',{attrs:{"cols":"12 text-right"}},[_c('b-button',{staticClass:"mt-2 mr-1 text-uppercase",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s("Añadir equipo")+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }